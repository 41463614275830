import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
// import Img from 'gatsby-image'
import { GatsbyImage,getImage,getSrc } from "gatsby-plugin-image"

const Cover = ({ post }) => {
    const image2 = getImage(post.coverImg)

  return post.coverImg ? (
    <div className="flex-shrink-0 mb-4 sm:ml-8 sm:mb-0 h-48 sm:h-auto overflow-hidden rounded">
      <Link to={`/post/${post.slug}`}>
        <GatsbyImage
          className="w-full sm:w-56 rounded"
      alt="my gatsby image"
    layout="fluid"
    transformOptions={{ grayscale: true }}
{...{ image2 }}
          // layout="fullWidth"
          // image={image2}
                // placeholder={{ fallback: data.file.childImageSharp.fixed.fallback }}

          // image={post.coverImg.childImageSharp}
          // fluid={post.coverImg.childImageSharp}

        />
      </Link>
    </div>
  ) : null
}

Cover.propTypes = {
  post: PropTypes.object.isRequired,
}

export default Cover
